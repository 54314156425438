import { useContext, useState } from "react";
import { eliminar, login } from "../../authContext/apiCalls";
import { AuthContext } from "../../authContext/AuthContext";
import { Link } from "react-router-dom";

import "./delete.scss";

export default function Delete() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mensaje, setMensaje] = useState("");
  
  const { dispatch } = useContext(AuthContext);

  let usert=useContext(AuthContext).error;

  const handleLogin = (e) => {
    e.preventDefault();
    if(email==="" || password==="")
    {
      setMensaje("Ingrese sus credenciales ...");
    }
    if(email!=="" && password!=="")
    {
      setMensaje("Validando credenciales ...");
      eliminar({ email, password }, dispatch);
    }
 
    
  };
  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="https://i.ibb.co/KryJLS1/logoyotta.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h1>Eliminar cuenta</h1>
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="loginButton" onClick={handleLogin}>
            Eliminar
          </button>
          
          <span>{usert}</span>
          
        </form>
      </div>
    </div>
  );
}